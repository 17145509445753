module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:400,700"]}},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149509903-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/vercel/workpath0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
